export const version = '1.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'vibrant'
};

// Google Maps API Key
export const MapsAPI = 'AIzaSyBuw-PkJjkjX_7e5rbUkomba8BzdA6QFqE';

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
