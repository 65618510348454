const adminRoutes = {
  access: 'administrator',
  name: 'Administration',
  icon: 'cog',
  children: [
    {
      to: '/projects',
      name: 'Projects',
      exact: true
    },
    {
      to: '/users',
      name: 'Users',
      exact: true
    },
    {
      to: '/companies',
      name: 'Companies',
      exact: true
    },
    {
      to: '/calendar',
      name: 'Calendar',
      exact: true
    }
  ]
};

const projectRoutes = {
  access: 'customer',
  name: 'Project',
  icon: 'chart-pie',
  children: [
    {
      name: 'Dashboard',
      to: '/project/dashboard',
      exact: true
    },
    {
      name: 'Project Items',
      to: '/project/items',
      exact: true
    },
    {
      name: 'Project Calendar',
      to: '/project/calendar',
      exact: true
    }
  ]
};

const serviceRoutes = {
  access: 'installer',
  name: 'Service',
  icon: 'tools',
  children: [
    {
      name: 'Schedule',
      to: '/service/services'
    }
  ]
};

export default [adminRoutes, projectRoutes, serviceRoutes];
