import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import RealmApolloProvider from '../graphql/RealmApolloProvider';
import { useRealmApp } from '../RealmApp';

import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));

const RequireLoggedInUser = ({ children }) => {
  // Only render children if there is a logged in user.
  const app = useRealmApp();
  return app.currentUser ? children : <Redirect to="/authentication/basic/login" />;
};

RequireLoggedInUser.propTypes = { children: PropTypes.node };

const Layout = () => {
  useEffect(() => {
    AuthBasicLayout.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/authentication/basic" component={AuthBasicLayout} />
        <RequireLoggedInUser>
          <RealmApolloProvider>
            <Route component={DashboardLayout} />
          </RealmApolloProvider>
        </RequireLoggedInUser>
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.TOP_RIGHT} />
    </Router>
  );
};

export default Layout;
