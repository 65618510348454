import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { APP_ID } from './context/Context';
import { RealmAppProvider } from './RealmApp';

ReactDOM.render(
  <Main>
    <RealmAppProvider appId={APP_ID}>
      <App />
    </RealmAppProvider>
  </Main>,
  document.getElementById('main')
);
