import React from 'react';
import * as Realm from 'realm-web';
import PropTypes from 'prop-types';

const RealmAppContext = React.createContext();

export const useRealmApp = () => {
  const app = React.useContext(RealmAppContext);
  if (!app) {
    throw new Error(`You must call useRealmApp() inside of a <RealmAppProvider />`);
  }
  return app;
};

export const RealmAppProvider = ({ appId, children }) => {
  const [app, setApp] = React.useState(new Realm.App(appId));
  React.useEffect(() => {
    setApp(new Realm.App(appId));
  }, [appId]);

  const [currentUser, setCurrentUser] = React.useState(app.currentUser);
  async function logIn(credentials) {
    // Call the logIn() method with the given credentials
    const profile = await app.logIn(credentials, true);
    if (profile.customData.active !== true) {
      await logOut();
      throw new Error('Access unauthorized.');
    }

    // app.currentUser is the user that just logged in
    setCurrentUser(app.currentUser);
  }
  async function logOut() {
    // Log out the currently active user
    await app.currentUser?.logOut();
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
    setCurrentUser(app.currentUser);
  }

  const wrapped = { ...app, currentUser, logIn, logOut };

  return <RealmAppContext.Provider value={wrapped}>{children}</RealmAppContext.Provider>;
};

RealmAppProvider.propTypes = { appId: PropTypes.string, children: PropTypes.node };
