import React, { useContext } from 'react';
import AppContext from '../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavItem } from 'reactstrap';

const ColorModeIcon = () => {
  const { setIsDark, isDark } = useContext(AppContext);

  return (
    <NavItem className="mx-2">
      <FontAwesomeIcon
        icon="adjust"
        className="fs-1 cursor-pointer hover-primary"
        onClick={() => {
          setIsDark(!isDark);
        }}
      />
    </NavItem>
  );
};

export default ColorModeIcon;
